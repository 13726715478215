import React from "react"
import Layout from "../components/Layout/Layout"
import HeaderImg from "../components/Components/HeaderImg";
import {Link} from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Kontakt() {
    return (
        <Layout>
           <HeaderImg/>
            <div className="container pb-5">
                <h1>Terapie BEMER®</h1>
                <p>BEMER® fyzikální cévní terapie stimuluje pomocí speciálního elektromagnetického signálu omezenou vazomoci (krev pohánějící pumpování nejmenších cév) a tím zlepšuje mikrocirkulaci.</p>
                <p>BEMER® fyzikální cévní terapie dokáže zlepšit omezené prokrvení těch nejmenších cév, a tím pádem podpořit tělu vlastní svépomocné ozdravovací a regenerační procesy.</p>
                <h2>Oblasti aplikace:</h2>
                <p>
                    <ul>
                        <li><span className="fw-bold">Terapie:</span> v případě onemocnění, podpora imunitní reakce, aktivace samoléčebných procesů stimulací mikrocirkulace</li>
                        <li><span className="fw-bold">Prevence:</span> pozitivní vliv na celkový stav, zdraví a spánek – to vše vede k lepší kvalitě života</li>
                        <li><span className="fw-bold">Zvýšení výkonu:</span> podpora látkové výměny pro nárůst výkonnosti, posílení schopnosti odezvy, snížení rizika sportovních zranění, rychlejší regenerace</li>
                        
                    </ul>
                </p>
                <h2>Úvodní terapie</h2>
                <p>Ke správné aplikaci potřebuji znát Váš zdravotní stav, a proto se první sezení může časově o něco protáhnout. V rámci zakoupení balíčku 10 aplikací máte první sezení zdarma. Celkem tedy absolvujete 11 terapií. </p>
                <p>Poskytuji poradenství, pronájem i prodej přístrojů BEMER. </p>
                <p>Více o mikrocirkulaci a Bemer terapii naleznete&nbsp;<a href="https://hrstkova.bemergroup.com/cs" target="_blank">zde</a>.</p>
                
                <div className="mt-5 w-25">
                    <StaticImage imgClassName={"janahrstkova"} src="../assets/custom-style/images/logobemer.jpg" />
                </div>
                <p className="fw-bold">Jana Hrstková</p>

                <h2>Cena:</h2>
                <p>Aplikace může trvat cca 15 - 30 min. / 300,- Kč za 1 aplikaci </p>
                <p>Balíček BEMER terapií: 10 aplikací / 2000,- Kč + 1. aplikace zdarma (úvodní sezení) </p>
                <p>Doporučuji minimálně 10 aplikací.</p>
                <p>Poradím Vám, jak si terapii BEMER dopřát i v pohodlí Vašeho domova, kdy jeden přístroj může využívat celá rodina i domácí mazlíčci.  </p>
                <p><span className="fw-bold">Pronájem</span> přístroje BEMER: cena na vyžádání (před vypůjčením preferuji osobní setkání)</p>
                <p><span className="fw-bold">Prodej</span> přístrojů BEMER: <a href="https://shop.bemergroup.com/cs_CZ/home?partner=hrstkova" target="_blank">zde</a> (před nákupem doporučuji konzultaci)</p>

            </div>

        </Layout>
    );
}